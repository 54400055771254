import React from 'react'
import { withMainLayout } from 'components/layouts'
import css from './style.module.scss'
import cn from 'classnames'
import { Link } from 'gatsby'
import SEO from 'components/seo'

export default withMainLayout(Contacts)

function Contacts() {
  return (
    <div className='container'>
      <SEO
        title='Контакты'
      />
      <div className={css.head}>
        <Link
          to='/'
          className={cn('linkBack caption primary-color', css.back)}
        >
          Вернуться на Главную
        </Link>
      </div>
      {/*<div className={css.block}>
        <h4 className={css.header}>
          Контакты
        </h4>
        <p>
          Телефон: <a href='tel:+74995580715'>+7(499)558-07-15</a><br/>
          Факс: <a href='tel:+74995580714'>+7(499)558-07-14</a>
        </p>
      </div>*/}
      <div className={css.block}>
        <h4 className={css.header}>
          Почта
        </h4>
        <p>
          По общим вопросам: <a href='mailto:shabatshalom1@mail.ru' target='_blank' rel='noreferrer'>shabatshalom1@mail.ru</a>
        </p>
      </div>
      <div className={css.block}>
        <h4 className={css.header}>
          Отчеты о нашей деятельности
        </h4>
        <p>
          <a href='http://unro.minjust.ru/NKOReports.aspx?request_type=nko' target='_blank' rel='noreferrer'>На сайте Министерства юстиции Российской Федерации</a>
        </p>
      </div>
      <div className={css.block}>
        <h4 className={css.header}>
          Адрес
        </h4>
        <p>127055, г. Москва, ул. Новосущевская, д.15</p>
      </div>
      <div className={css.block}>
        <h4 className={css.header}>
          Реквизиты
        </h4>
        <p>
          МЕЖДУНАРОДНЫЙ БЛАГОТВОРИТЕЛЬНЫЙ ФОНД РАЗВИТИЯ И СОХРАНЕНИЯ КУЛЬТУРЫ И ТРАДИЦИЙ "ОР АВНЕР"
        </p>
        <br/>
        <p>
          ИНН: 770116675<br />
          КПП: 771501001<br />
          ОГРН: 1027739515650<br />
          Р/С: 40703810238000005145<br />
          В ПАО СБЕРБАНК, г. Москва<br />
          К/С: 30101810400000000225<br />
          БИК: 044525225
        </p>
      </div>
      <br/>
      <br/>
    </div>
  )
}